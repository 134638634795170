<template>
  <div class="body-main">
    <div class="main">
      <div class="main-l">
        <div class="main-l-title">
          <i class="icon"></i>
          <span class="text">{{product_nm}}（产品编号：{{product_bn}}）</span>
        </div>
        <div class="main-l-content">
          <div class="main-l-content-l">
            <el-image
              class="main-img"
              :src="require('@/assets/index/icon-005.png')"
              alt
            ></el-image>
          </div>
          <div class="main-l-content-r">
            <div class="box-1">
              包车日期：{{
                $route.query.date || $route.query.bill_date | timeFormatYYYYMMDD
              }}
            </div>
            <div
              class="box-2"
              :class="[hasLength(timeObj) ? 'ta-l' : '']"
              @click="isShowTime = true"
            >
              <i class="icon-1"></i>
              <span class="text" v-if="hasLength(timeObj)"
                >具体送车时间 {{ timeObj.hour | timeFormat }}:{{
                  timeObj.minute | timeFormat
                }}</span
              >
              <span class="text" v-else>具体送车时间（小时）</span>
            </div>
            <div
              class="box-2"
              :class="[hasLength(infoObj) ? 'ta-l' : '']"
              @click="isShowInfo = true"
            >
              <i class="icon-3"></i>
              <span class="text" v-if="hasLength(infoObj)"
                >包车人 {{ infoObj.name }} +{{ infoObj.tel_code }}-{{
                  infoObj.tel
                }}
                {{ infoObj.start_posi }} {{ infoObj.end_posi }}</span
              >
              <span class="text" v-else>包车人信息</span>
            </div>
            <div
              class="box-2"
              :class="[hasLength(numObj) ? 'ta-l' : '']"
              @click="isShowNum = true"
            >
              <i class="icon-3"></i>
              <span class="text" v-if="hasLength(numObj)"
                >乘客{{ numObj.passenger_num }}人 行李{{
                  numObj.luggage_num
                }}件</span
              >
              <span class="text" v-else>请填写载客数量</span>
            </div>
            <div class="box-1">
              <i class="icon-2"></i>
              <input class="text" v-model="bill_demo" placeholder="订单备注" />
            </div>
            <el-checkbox
              label="美食/游遍欧洲网顾问为我提供了该产品的咨询服务"
              name="type"
              class="ml-20"
              v-model="checked"
            ></el-checkbox>
          </div>
        </div>
      </div>
      <div class="main-r">
        <div class="main-r-title">
          订购人信息
          <span class="require">（必填）</span>
        </div>
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="45px">
          <el-form-item prop="name" label="姓名">
            <el-input size="medium" v-model="form.name"></el-input>
          </el-form-item>
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item prop="tel_code" label="手机">
                <el-select
                  size="medium"
                  v-model="form.tel_code"
                  filterable
                  placeholder="选择国家"
                >
                  <el-option
                    v-for="(item, index) in article"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="tel">
                <el-input
                  size="medium"
                  v-model="form.tel"
                  placeholder="数字/空格/-/0"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="email" label="邮箱">
            <el-input size="medium" v-model="form.email"></el-input>
          </el-form-item>
        </el-form>
        <div class="main-r-tips">
          已选
          <span class="num">1</span>
          件，合计：
          <span class="price">{{ $route.query.car_order_car_price }}</span>
        </div>
        <div class="submit-button" @click="submitForm">提交订单</div>
      </div>
    </div>
    <dialog-info
      :dialogVisible.sync="isShowInfo"
      @confirm="infoConfirm"
    ></dialog-info>
    <dialog-num
      :dialogVisible.sync="isShowNum"
      @confirm="numConfirm"
    ></dialog-num>
    <dialog-time
      :dialogVisible.sync="isShowTime"
      @confirm="timeConfirm"
    ></dialog-time>
  </div>
</template>

<script>
import article from '@/utils/article';
import DialogInfo from './components/DialogInfo';
import DialogNum from './components/DialogNum';
import DialogTime from './components/DialogTime';
import { carOrderInsert } from '@/api/index';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
  name: 'rentCarOrder',
  components: {
    DialogInfo,
    DialogNum,
    DialogTime,
  },
  data: function () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      form: {
        name: '',
        tel_code: '',
        tel: '',
        email: '',
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        tel_code: [
          { required: true, message: '请选择国家', trigger: 'change' },
        ],
        tel: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      },
      value: '',
      isShowTime: false,
      isShowInfo: false,
      isShowNum: false,
      timeObj: {},
      infoObj: {},
      numObj: {},
      bill_demo: '',
      article: article,
      product_bn: '',
      product_nm: '',
      checked: true
    };
  },
  filters: {
    timeFormat(value) {
      let resultValue = '';
      value = parseInt(value);
      if (value < 10 && (value + '')[0] !== 0) {
        resultValue = '0' + value;
      } else {
        resultValue = value;
      }
      return resultValue;
    },
  },
  created() {
    this.product_nm = this.$route.query.product_nm;
    this.product_bn = this.$route.query.product_bn;
    this.dataInit();
  },
  computed: {
    ...mapState(['home']),
    currency() {
      return this.$store.state.home.currency.currency_id;
    },
  },
  methods: {
    // 数据回填
    dataInit() {
      // {
      //   bill_currency: "1"
      //   bill_date: "2020-08-25T16:00:00.000000Z"
      //   bill_demo: "我的"
      //   bill_status: "1"
      //   bill_user_email: "7894561232qq.com"
      //   bill_user_nm: "为三房"
      //   bill_user_tel: "15046448321"
      //   car_arrive_time: "2020-08-25T16:00:00.000000Z"
      //   car_type_id: "d53a1f7d4066402d85abc381ea36db21"
      //   car_user_nm: "我发的"
      //   car_user_tel: "15046448320"
      //   get_down_place: "大连"
      //   get_on_place: "北京"
      //   id: "8838397cae774db1a5fb04066926e7b1"
      //   opet_demo: "1"
      //   order_date: "2020-08-25T16:00:00.000000Z"
      //   pay_status: "1"
      //   product_id: "058a7e4974944474b6597405b4909e3d"
      //   product_nm: "柏林市游及周边"
      //   real_luggage_num: 3
      //   real_passenger_num: 3
      // }
      const {
        bill_currency,
        bill_date,
        bill_demo,
        bill_status,
        bill_user_email,
        bill_user_nm,
        bill_user_tel,
        car_arrive_time,
        car_type_id,
        car_user_nm,
        car_user_tel,
        get_down_place,
        get_on_place,
        id,
        opet_demo,
        order_date,
        pay_status,
        product_id,
        product_nm,
        real_luggage_num,
        real_passenger_num,
      } = this.$route.query;
      this.form.name = car_user_nm;
      this.form.tel = bill_user_tel;
      this.form.email = bill_user_email;
      this.bill_demo = bill_demo;
      this.form.email = bill_user_email;
      this.infoObj.name = car_user_nm;
      this.infoObj.tel = car_user_tel;
      this.infoObj.start_posi = get_on_place;
      this.infoObj.end_posi = get_down_place;
      this.numObj.passenger_num = real_passenger_num;
      this.numObj.luggage_num = real_luggage_num;
      console.log(moment(car_arrive_time).format('YYYY-MM-DD HH:mm:ss'));
      this.timeObj.hour = moment(car_arrive_time).format('HH');
      this.timeObj.minute = moment(car_arrive_time).format('mm');
    },
    hasLength(value) {
      return Object.keys(value).length > 0;
    },
    timeConfirm(form) {
      console.log(form);
      this.timeObj = form;
    },
    infoConfirm(form) {
      console.log(form);
      this.infoObj = form;
    },
    numConfirm(form) {
      console.log(form);
      this.numObj = form;
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid && this.checked) {
          let arr = /\w*-[\u4e00-\u9fa5]*-(\d*)/.exec(this.form.tel_code);
          console.log(arr);
          this.form.tel_code = arr && arr[1];
          const { date, id, car_type_id } = this.$route.query;
          const {
            timeObj: { hour, minute },
            infoObj: { name, tel_code, tel, start_posi, end_posi },
            numObj: { passenger_num, luggage_num },
            bill_demo,
          } = this;
          const {
            name: formName,
            tel_code: formTelCode,
            tel: formTel,
            email: formEmail,
          } = this.form;
          let isoBillDate = new Date();
          isoBillDate.getTimezoneOffset();
          isoBillDate.setHours(isoBillDate.getHours() + 8);

          let isoOrderDate = new Date(date);
          isoOrderDate.getTimezoneOffset();
          isoOrderDate.setHours(isoBillDate.getHours() + 8);

          let isoCarArriveDate = moment(date).format('YYYY-MM-DD') +
              `T${hour}:${minute}:00.000000Z`;
          
          const postData = {
            bill_currency: this.currency, // 下单币种
            // order_date: moment(date).format('YYYY-MM-DD') + 'T16:00:00.000000Z', // 包车日期
            order_date: isoOrderDate.toISOString(), // 包车日期
            // car_arrive_time: `${hour}:${minute}`, // 具体送车时间
            car_arrive_time: isoCarArriveDate, // 具体送车时间
            car_user_nm: name, // 用车人姓名
            car_user_tel: `${tel_code} ${tel}`, // 当时联系手机
            get_on_place: start_posi, // 上车地点
            get_down_place: end_posi, // 下车地点
            real_passenger_num: passenger_num, // 载客数量
            real_luggage_num: luggage_num, // 行李数
            bill_demo: bill_demo, // 订单备注
            bill_user_nm: formName, // 订购人姓名
            bill_user_tel: `${formTelCode} ${formTel}`, // 订购人手机
            bill_user_email: formEmail, // 订购人email
            bill_date: isoBillDate.toISOString(), // 订购日期
            pay_status: '1', // 付款状态
            bill_status: '1', // 订单状态
            opet_demo: '1', // 执行备注
            product_id: id, // 车导项目
            car_type_id: car_type_id,
            cur_user_id: this.home.userInfo.uid
          };
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          carOrderInsert(postData)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message({
                message: res.message,
                type: 'success',
              });
              this.$router.push({
                path: '/',
              });
            })
            .catch((err) => {
              console.log(err);
              loading.close();
              this.$message({
                message: err.message,
                type: 'error',
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-main {
  width: 100%;
  display: flex;
  background: rgba(245, 245, 245, 1);
  justify-content: center;
  padding-bottom: 55px;
  .main {
    margin-top: 25px;
    width: 1200px;
    display: flex;
    align-items: flex-start;
    &-l {
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(221, 221, 221, 1);
      border-radius: 4px;
      padding: 30px 25px;
      width: 880px;
      margin-right: 20px;
      position: relative;
      &-close {
        cursor: pointer;
        position: absolute;
        top: 3px;
        right: 1px;
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url('../../assets/index/icon-012.png') no-repeat;
      }
      &-title {
        display: flex;
        padding-bottom: 10px;
        align-items: center;
        .icon {
          margin-right: 12px;
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url('../../assets/index/icon-013.png') no-repeat;
        }
        .text {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 24px;
        }
      }
      &-content {
        display: flex;
        &-l {
          width: 220px;
        }
        &-r {
          flex: 1;
          padding-left: 20px;
          .box-1 {
            height: 30px;
            border: 1px solid rgba(221, 221, 221, 1);
            border-radius: 4px;
            margin-bottom: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            line-height: 28px;
            margin-left: 20px;
            text-align: center;
            position: relative;
            padding: 0px 40px;
            .icon-2 {
              position: absolute;
              top: 4px;
              left: 10px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('../../assets/index/icon-018.png') no-repeat;
            }
            input {
              width: 100%;
              border: none;
              text-align: center;
              height: 25px;
            }
          }
          .box-2 {
            height: 30px;
            border: 1px solid rgba(61, 126, 255, 1);
            border-radius: 4px;
            cursor: pointer;
            margin-bottom: 10px;
            position: relative;
            padding: 0px 40px;
            text-align: center;
            margin-left: 20px;
            &::before {
              content: '*';
              position: absolute;
              top: 2px;
              left: -20px;
              font-size: 21px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: rgba(254, 0, 0, 1);
              line-height: 30px;
            }
            .icon-1 {
              position: absolute;
              top: 4px;
              left: 10px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('../../assets/index/icon-019.png') no-repeat;
            }

            .icon-3 {
              position: absolute;
              top: 4px;
              left: 10px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('../../assets/index/icon-020.png') no-repeat;
            }
            .text {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(61, 126, 255, 1);
              line-height: 28px;
            }
            &.ta-l {
              text-align: left;
              // border: 1px solid #dddddd;
              // .text {
              // color: #666666;
              // }
            }
          }
          .ml-20 {
            margin-left: 20px;
          }
        }
      }
    }
    &-r {
      flex: 1;
      padding: 20px 20px 20px 10px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(221, 221, 221, 1);
      border-radius: 4px;
      &-title {
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        margin-bottom: 20px;
        .require {
          font-size: 14px;
        }
      }
      :deep(.el-row) {
        display: flex;
        align-content: center;
      }
      :deep(.el-col:last-child) :deep(.el-form-item__content) {
        margin-left: 0px !important;
      }
      :deep(.el-form-item__label) {
        padding-right: 5px;
      }
      :deep(.vue-tel-input) {
        height: 36px;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        line-height: 36px;
        .vti__dropdown-list.below {
          top: 36px;
          left: -150px;
        }
      }

      &-tips {
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        margin-bottom: 20px;
        .num {
          color: #ff0000;
        }
        .price {
          font-size: 26px;
          color: #ff0000;
        }
      }
      &-select {
        text-align: right;
        :deep(.el-select) {
          width: 90px;
        }
      }
      .submit-button {
        cursor: pointer;
        width: 252px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        background: rgba(111, 15, 149, 1);
        border-radius: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
}
</style>