<template>
  <el-dialog
    title="具体送车时间（24小时制）"
    :visible.sync="dialogVisible"
    :before-close="cancel"
  >
    <i class="clock-icon"></i>
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="小时" prop="hour">
        <el-input-number
          v-model="form.hour"
          size="small"
          @change="handleChange"
          :min="0"
          :max="23"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="分钟" prop="minute">
        <el-input-number
          v-model="form.minute"
          size="small"
          @change="handleChange"
          :min="0"
          :max="59"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <div class="confirm-button" @click="confirm">确定</div>
      <div class="cancel-button" @click="cancel">取消</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DialogTime',
  data() {
    return {
      form: {
        hour: 0,
        minute: 0,
      },
      rules: {
        hour: [{ required: true, message: '请输入小时', trigger: 'blur' }],
        minute: [{ required: true, message: '请输入分钟', trigger: 'blur' }],
      },
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
    },
  },
  methods: {
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('update:dialogVisible', false);
          this.$emit('confirm', this.form);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$emit('update:dialogVisible', false);
    },
    handleChange() {},
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
  width: 400px;
}
:deep(.el-dialog__title) {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}
:deep(.el-dialog__body) {
  padding-bottom: 0px;
}
:deep(.el-form) {
  display: flex;
  justify-content: space-around;
}
:deep(.el-form-item) {
  text-align: center;
}
:deep(.el-form-item__label) {
  float: none;
}
.clock-icon {
  margin: 0 auto;
  display: block;
  width: 97px;
  height: 97px;
  background: url('../../../assets/index/icon-021.png') no-repeat;
}
.dialog-footer {
  text-align: center;
  margin-bottom: 8px;
  .confirm-button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 35px;
    background: rgba(111, 15, 149, 1);
    border-radius: 18px;
    margin-right: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 254, 254, 1);
    line-height: 35px;
    text-align: center;
  }
  .cancel-button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 35px;
    border: 1px solid rgba(111, 15, 149, 1);
    border-radius: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(111, 15, 149, 1);
    line-height: 33px;
    text-align: center;
  }
}
</style>