<template>
  <el-dialog
    title="乘客数量"
    :visible.sync="dialogVisible"
    :before-close="cancel"
  >
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-width="formLabelWidth"
    >
      <el-form-item label="载客数量" prop="passenger_num">
        <el-input-number
          v-model="form.passenger_num"
          size="small"
          :min="1"
          :max="10"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="行李数" prop="luggage_num">
        <el-input-number
          v-model="form.luggage_num"
          size="small"
          :min="0"
          :max="10"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <div class="confirm-button" @click="confirm">确定</div>
      <div class="cancel-button" @click="cancel">取消</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DialogNum',
  data() {
    return {
      formLabelWidth: '70px',
      form: {
        passenger_num: '',
        luggage_num: '',
      },
      rules: {
        passenger_num: [{ required: true, message: '', trigger: 'change' }],
        luggage_num: [{ required: true, message: '', trigger: 'change' }],
      },
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
    },
  },
  methods: {
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('update:dialogVisible', false);
          this.$emit('confirm', this.form);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$emit('update:dialogVisible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
  width: 400px;
}

:deep(.el-dialog__title) {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}
:deep(.el-dialog__body) {
  padding-bottom: 0px;
}
.dialog-footer {
  text-align: center;
  margin-bottom: 8px;
  .confirm-button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 35px;
    background: rgba(111, 15, 149, 1);
    border-radius: 18px;
    margin-right: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 254, 254, 1);
    line-height: 35px;
    text-align: center;
  }
  .cancel-button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 35px;
    border: 1px solid rgba(111, 15, 149, 1);
    border-radius: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(111, 15, 149, 1);
    line-height: 33px;
    text-align: center;
  }
}
</style>