<template>
  <el-dialog
    title="包车人信息"
    :visible.sync="dialogVisible"
    :before-close="cancel"
  >
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-width="formLabelWidth"
    >
      <el-form-item label="用车人姓名" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="tel_code" label="当日联系手机">
            <el-select
              size="medium"
              v-model="form.tel_code"
              filterable
              placeholder="选择国家"
            >
              <el-option
                v-for="(item, index) in article"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="tel">
            <el-input
              size="medium"
              v-model="form.tel"
              placeholder="数字/空格/-/0"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="上车地点" prop="start_posi">
        <el-input v-model="form.start_posi" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="下车地点" prop="end_posi">
        <el-input v-model="form.end_posi" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <div class="confirm-button" @click="confirm">确定</div>
      <div class="cancel-button" @click="cancel">取消</div>
    </div>
  </el-dialog>
</template>

<script>
import article from '@/utils/article';
export default {
  name: 'DialogInfo',
  data() {
    return {
      article: article,
      formLabelWidth: '110px',
      form: {
        name: '',
        tel_code: '',
        tel: '',
        start_posi: '',
        end_posi: '',
      },
      rules: {
        name: [
          { required: true, message: ' 请输入用车人姓名', trigger: 'blur' },
        ],
        tel_code: [
          { required: true, message: '请选择国家', trigger: 'change' },
        ],
        tel: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        start_posi: [
          { required: true, message: '请输入上车地点', trigger: 'blur' },
        ],
        end_posi: [
          { required: true, message: '请输入下车地点', trigger: 'blur' },
        ],
      },
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
    },
  },
  methods: {
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('update:dialogVisible', false);
          let arr = /\w*-[\u4e00-\u9fa5]*-(\d*)/.exec(this.form.tel_code);
          this.form.tel_code = arr[1];
          this.$emit('confirm', this.form);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$emit('update:dialogVisible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
  width: 500px;
}
:deep(.el-dialog__title) {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}
:deep(.el-dialog__body) {
  padding-bottom: 0px;
}
:deep(.el-col:last-child) :deep(.el-form-item__content) {
  margin-left: 0px !important;
}
.dialog-footer {
  text-align: center;
  margin-bottom: 8px;
  .confirm-button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 35px;
    background: rgba(111, 15, 149, 1);
    border-radius: 18px;
    margin-right: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 254, 254, 1);
    line-height: 35px;
    text-align: center;
  }
  .cancel-button {
    cursor: pointer;
    display: inline-block;
    width: 110px;
    height: 35px;
    border: 1px solid rgba(111, 15, 149, 1);
    border-radius: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(111, 15, 149, 1);
    line-height: 33px;
    text-align: center;
  }
}
</style>